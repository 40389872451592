import "./App.css";
import { Fragment, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/admin/dashboard/Dashboard";
import { AdminLayout } from "./layout/admin/layout/AdminLayout";
import { Layout } from "./layout/client/layout/Layout";
import { Category } from "./pages/admin/category/Category";
import { SubCategory } from "./pages/admin/category/SubCategory";
import { Slider } from "./pages/admin/slider/Slider";
import { Blog } from "./pages/admin/blog/Blog";
import { Company } from "./pages/admin/company/Company";
import Home from "./pages/client/home/Home";
import CompanyList from "./pages/client/companyList/CompanyList";
import CompanyDetail from "./pages/client/companyDetail/CompanyDetail";
import { CommentWaitingApprove } from "./pages/admin/comment/CommentWaitingApprove";

function App() {
  // useEffect(() => {
  //     const google = document.createElement("link");
  //     const gRecap = document.createElement("script");
  //
  //     google.rel = "preconnect";
  //     google.href = "https://www.google.com";
  //     document.head.appendChild(google);
  //
  //     gRecap.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GRECAPKEY}`;
  //     document.body.appendChild(gRecap);
  // }, []);
  return (
    <Fragment>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/companyList" element={<CompanyList />} />
          <Route path="/company/:name/:id" element={<CompanyDetail />} />
        </Route>
        <Route exact path="/admin" element={<AdminLayout />}>
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/blog" element={<Blog />} />
          <Route path="/admin/slider" element={<Slider />} />
          <Route path="/admin/company" element={<Company />} />
          <Route path="/admin/category" element={<Category />} />
          <Route path="/admin/category/subCategory" element={<SubCategory />} />
          <Route
            path="/admin/comment/waitingApprove"
            element={<CommentWaitingApprove />}
          />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
