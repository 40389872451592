import React, { useEffect, useState } from "react";
import classes from "./GiveStar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";

const GiveStar = ({ setFormInput, setFormIsValid }) => {
  const [rate, setRate] = useState(0);

  useEffect(() => {
    if (rate != null && rate !== 0) {
      setFormIsValid((prev) => ({
        ...prev,
        rate: { status: true, message: "" },
      }));
    }
    if (rate !== 0) {
      setFormInput((prev) => ({
        ...prev,
        rate: rate,
      }));
    }
  }, [rate]);
  return (
    <div className={classes.container}>
      <FontAwesomeIcon
        icon={
          rate === 0
            ? faStarEmpty
            : rate > 0 && rate < 1
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
        onClick={() => setRate(1)}
      />
      <FontAwesomeIcon
        icon={
          rate <= 1
            ? faStarEmpty
            : rate > 1 && rate < 2
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
        onClick={() => setRate(2)}
      />
      <FontAwesomeIcon
        icon={
          rate <= 2
            ? faStarEmpty
            : rate > 2 && rate < 3
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
        onClick={() => setRate(3)}
      />
      <FontAwesomeIcon
        icon={
          rate <= 3
            ? faStarEmpty
            : rate > 3 && rate < 4
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
        onClick={() => setRate(4)}
      />
      <FontAwesomeIcon
        icon={
          rate <= 4
            ? faStarEmpty
            : rate > 4 && rate < 5
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
        onClick={() => setRate(5)}
      />
    </div>
  );
};

export default GiveStar;
