import React, { Fragment, useContext, useState } from "react";
import { ConvertFileBase64, EntityStatusData } from "../../../util/commonUtil";
import { Input } from "../../ui/input/Input";
import {
  SwalErrorMessage,
  SwallApprove,
  SwalSuccess,
  SwalSuccessMessage,
} from "../../../util/swalUtil";
import { AppContext } from "../../../context/app-context";
import classes from "./AddComment.module.css";
import { CheckFormValid, Validation } from "../../ui/input/Validation";
import GiveStar from "../../ui/giveStar/GiveStar";

export const AddComment = ({ formInput, setFormInput, setShowModal }) => {
  const appContext = useContext(AppContext);

  const [formInputIsValid, setFormInputIsValid] = useState({
    rate: { status: false, message: "Puan: Zorunludur." },
    title: { status: false, message: "Başlık: Zorunludur." },
    comment: { status: false, message: "Yorum: Zorunludur." },
  });

  const SubmitHandler = async (event) => {
    event.preventDefault();
    let isValid = { status: false, message: "Hata" };
    isValid = CheckFormValid(formInputIsValid);
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }
    await SwallApprove(null, "Yorumunuz kaydedilecek.").then(async (res) => {
      if (res.isConfirmed) {
        const response = await appContext.FetchHandler(
          "comment",
          "post",
          null,
          formInput,
        );

        if (response.ok) {
          event.target.reset();
          setShowModal(false);
          SwalSuccessMessage(response.data);
        }
      }
    });
  };
  return (
    <Fragment>
      <form
        id="form"
        className="form"
        onSubmit={async (event) => {
          event.preventDefault();
          await SubmitHandler(event);
        }}
      >
        <h2>Memnunsan Yaz</h2>

        <div className={classes.starBox}>
          <label>Puanınız</label>
          <GiveStar
            setFormInput={setFormInput}
            setFormIsValid={setFormInputIsValid}
          />
          {formInput.rate != null && !formInputIsValid.rate.status && (
            <span className={classes.error}>
              {formInputIsValid.rate.message}
            </span>
          )}
        </div>

        <Input
          label="Başlık"
          type="input"
          style={{ width: "100%" }}
          validation={formInput?.title != null && formInputIsValid.title}
          input={{
            id: "title",
            type: "text",
            value:
              formInput != null && formInput.title != null
                ? formInput.title
                : "",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                title: Validation("Başlık", value, {
                  isRequired: true,
                  min: 3,
                  max: 50,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                title: value,
              }));
            },
          }}
        />
        <Input
          label="Yorumunuz"
          type="textArea"
          style={{ width: "100%" }}
          validation={formInput?.comment != null && formInputIsValid.comment}
          input={{
            id: "comment",
            type: "text",
            value:
              formInput != null && formInput.comment != null
                ? formInput.comment
                : "",
            onChange: (event) => {
              const value = event.target.value;
              setFormInputIsValid((prev) => ({
                ...prev,
                comment: Validation("Yorumunuz", value, {
                  isRequired: true,
                  min: 10,
                  max: 1500,
                }),
              }));
              setFormInput((prev) => ({
                ...prev,
                comment: value,
              }));
            },
          }}
        />

        <Input
          label="Youtube Linki (örn: https://www.youtube.com/watch?v=jNQXAC9IVRw)"
          type="input"
          style={{ width: "100%" }}
          input={{
            id: "youtubeUrl",
            type: "text",
            value:
              formInput != null && formInput.youtubeUrl != null
                ? formInput.youtubeUrl
                : "",
            onChange: (event) => {
              const value = event.target.value;
              setFormInput((prev) => ({
                ...prev,
                youtubeUrl: value,
              }));
            },
          }}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="submit"
            className="btnSave"
            style={{ backgroundColor: "#FF0000" }}
          >
            Memnunsan Yaz
          </button>
        </div>
      </form>
    </Fragment>
  );
};
