import React, { useContext, useEffect, useState } from "react";
import classes from "./companyDetail.module.css";
import { useLocation, useParams } from "react-router";
import { AppContext } from "../../../context/app-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReviewStar from "../../../components/ui/reviewStar/ReviewStar";
import HtmlReactParser from "html-react-parser";
import CommentBox from "../../../components/client/commentBox/CommentBox";
import { ModalAdmin } from "../../../components/admin/modal/ModalAdmin";
import { ModalClient } from "../../../components/ui/modal/ModalClient";
import GiveStar from "../../../components/ui/giveStar/GiveStar";
import { AddComment } from "../../../components/client/addComment/AddComment";

const CompanyDetail = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const state = location.state;
  const { name, id } = useParams();
  const [companyData, setCompanyData] = useState(state);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [crudState, setCrudState] = useState(false);
  const [commentData, setCommentData] = useState([]);

  const [formInput, setFormInput] = useState({
    rate: null,
    title: null,
    comment: null,
    youtubeUrl: null,
    companyId: id,
  });

  useEffect(() => {
    GetCompany();
  }, [location]);

  const GetCompany = async () => {
    const response = await appContext.FetchHandler(
      "Company/Detail",
      "post",
      null,
      { id: id },
    );

    if (response.ok) {
      setCompanyData(response.data);
    }
  };

  useEffect(() => {
    GetComments();
  }, [crudState]);

  const GetComments = async () => {
    const response = await appContext.FetchHandler(
      "comment/companyComments",
      "post",
      null,
      { id: id },
    );
    console.log("GetComments", response.data);
    if (response.ok) {
      setCommentData(response.data);
      const userComment = Array.from(response.data).filter(
        (x) => x.isUserComment,
      )[0];
      if (userComment !== undefined) {
        console.log("userComment", userComment);
      }
    }
  };

  return (
    <div className="pageContainerColumn">
      {showCommentModal && (
        <ModalClient
          showModal={showCommentModal}
          setShowModal={setShowCommentModal}
        >
          <AddComment
            formInput={formInput}
            setFormInput={setFormInput}
            setShowModal={setShowCommentModal}
            companyId={companyData.id}
            setCrudState={setCrudState}
          />
        </ModalClient>
      )}
      {companyData && (
        <div className={classes.companyContainer}>
          <div className={classes.companyTop}>
            <div className={classes.companyTopLeft}>
              <div className={classes.companyTopLeftTop}>
                <img src={companyData.mediaUrl} alt={companyData.name} />
                <h1>{companyData.name}</h1>
              </div>
              <div className={classes.companyTopLeftBottom}>
                <ReviewStar rate={companyData.rate} />
                <p>
                  Toplam Yorum:
                  <span style={{ fontWeight: "bold", marginLeft: ".5rem" }}>
                    {companyData.totalComment}
                  </span>
                </p>
              </div>
            </div>
            <div className={classes.companyTopMiddle}>
              <div className={classes.contactItem}>
                <FontAwesomeIcon
                  icon={faPhone}
                  className={classes.contactItemIcon}
                />
                <Link
                  to={`tel:${companyData.phoneNumber?.replaceAll(" ", "")}`}
                >
                  {companyData.phoneNumber}
                </Link>
              </div>
              <div className={classes.contactItem}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className={classes.contactItemIcon}
                />
                <Link to={`mailto:${companyData.email?.replaceAll(" ", "")}`}>
                  {companyData.email}
                </Link>
              </div>
              <div className={classes.contactItem}>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className={classes.contactItemIcon}
                />
                <Link to="#">
                  {companyData.address}
                  <br />
                  {companyData.city} /{companyData.country}
                </Link>
              </div>
            </div>
            <div className={classes.companyTopRight}>
              <button
                className={classes.memnunsanYazBtn}
                onClick={() => setShowCommentModal(true)}
              >
                Memnunsan Yaz
              </button>
            </div>
          </div>
          <div className={classes.companyBottom}>
            <p>{companyData.shortDescription}</p>
            {companyData.longDescription &&
              HtmlReactParser(companyData.longDescription)}
          </div>
        </div>
      )}
      <div className={classes.commentContainer}>
        <CommentBox data={commentData} />
      </div>
    </div>
  );
};

export default CompanyDetail;
