import React, { useContext, useEffect, useState } from "react";
import classes from "./Home.module.css";
import MainSlider from "../../../components/client/mainSlider/MainSlider";
import { AppContext } from "../../../context/app-context";
import CommentBox from "../../../components/client/commentBox/CommentBox";
import { Link } from "react-router-dom";

const Home = () => {
  const appContext = useContext(AppContext);
  const [sliderData, setSliderData] = useState([]);
  const [commentData, setCommentData] = useState([]);

  useEffect(() => {
    GetSliderData();
    GetLastComments();
  }, []);
  const GetSliderData = async () => {
    const response = await appContext.FetchHandler("slider", "get", null, null);

    if (response.ok) {
      setSliderData(response.data);
    }
  };

  const GetLastComments = async () => {
    const response = await appContext.FetchHandler(
      "comment/lastComments",
      "get",
      null,
      null,
    );

    if (response.ok) {
      setCommentData(response.data);
    }
  };

  return (
    <div className="pageContainerColumn">
      <MainSlider data={sliderData} />
      {commentData && (
        <div className={classes.comments}>
          <div className={classes.commentsTop}>
            <h2>En Son Yorumlar</h2>
            <Link to="/">Tümünü Gör</Link>
          </div>
          <div className={classes.commentBottom}>
            <CommentBox widget={true} data={commentData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
