import React from "react";
import classes from "./ReviewStar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";

const ReviewStar = ({ rate }) => {
  return (
    <div className={classes.container}>
      <FontAwesomeIcon
        icon={
          rate === 0
            ? faStarEmpty
            : rate > 0 && rate < 1
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
      />
      <FontAwesomeIcon
        icon={
          rate <= 1
            ? faStarEmpty
            : rate > 1 && rate < 2
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
      />
      <FontAwesomeIcon
        icon={
          rate <= 2
            ? faStarEmpty
            : rate > 2 && rate < 3
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
      />
      <FontAwesomeIcon
        icon={
          rate <= 3
            ? faStarEmpty
            : rate > 3 && rate < 4
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
      />
      <FontAwesomeIcon
        icon={
          rate <= 4
            ? faStarEmpty
            : rate > 4 && rate < 5
              ? faStarHalfStroke
              : faStar
        }
        className={classes.icon}
      />
    </div>
  );
};

export default ReviewStar;
