import moment from "moment";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";

export const SliceText = (text, count) => {
  if (text.length > count) {
    text = text.slice(0, parseInt(count) - 3);
    text = `${text}...`;
  }
  return text;
};

export const RgbaConvert = (value, opacity) => {
  const red = parseInt(value.substring(1, 3), 16);
  const green = parseInt(value.substring(3, 5), 16);
  const blue = parseInt(value.substring(5, 7), 16);

  const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return rgba;
};

export const FormatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(90|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+90 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

export const ConvertFileBase64 = (file) => {
  return new Promise((resolve, rejest) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
      // const base64 = reader.result;
      const preview = e.target.result;
      resolve({ base64, preview });
    };
  });
};

export const GetFileExtension = (filename) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
};

export const ScrollTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const ScrollModalAdminTop = () => {
  const modal = document.getElementById("modalAdmin");
  modal.scrollTop = 0;
};

export const FormatDateWithClock = (date) => {
  return moment(date).format("DD.MM.YYYY : HH:mm");
};

export const FormatDate = (date) => {
  return moment(date).format("DD.MM.YYYY");
};

export const ConvertCharset = (value) => {
  if (value == null) return "";
  const locales = ["tr", "TR", "tr-TR", "tr-u-co-search", "tr-x-turkish"];

  value = value
    .toString()
    .replaceAll("İ", "i")
    .toLocaleLowerCase(locales)
    .replaceAll("ç", "c")
    .replaceAll("ğ", "g")
    .replaceAll("ü", "u")
    .replaceAll("ı", "i")
    .replaceAll("ö", "o")
    .replaceAll("ş", "s");
  return value;
};

export const ConvertCurrency = (value) => {
  if (value === undefined || value.length === 0) return null;
  value = value.toLocaleString("tr-TR", { style: "currency", currency: "TRY" });
  return value;
};

export const DownloadExcel = (dataSource, sheetName, fileName) => {
  const ws = XLSX.utils.json_to_sheet(dataSource);
  const header = Object.keys(dataSource[0]);
  var wscols = [];
  for (let i = 0; i < header.length; i++) {
    wscols.push({ wch: header[i].length + 14 });
  }
  ws["!cols"] = wscols;
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFileXLSX(
    wb,
    `${fileName}-${Math.floor(Math.random() * 99999)}.xlsx`,
  );
  return true;
};

export const DataTableProcess = (data, DeleteHandler, EditHandler, linkUrl) => {
  return (
    <div className="dataTableProcess">
      {linkUrl ? (
        <Link
          className="dataTableProcessEdit"
          style={{ paddingBottom: "6px" }}
          to={linkUrl}
          state={data}
        >
          Düzenle
        </Link>
      ) : (
        <button
          className="dataTableProcessEdit"
          onClick={(data) => EditHandler(data)}
        >
          Düzenle
        </button>
      )}
      {DeleteHandler && (
        <button
          className="dataTableProcessDelete"
          id={data.id}
          onClick={(event) => DeleteHandler(event)}
        >
          Sil
        </button>
      )}
    </div>
  );
};

export const DataTableImage = (imgUrl) => {
  return (
    <img
      src={imgUrl}
      alt=""
      style={{
        width: "100%",
        height: "100px",
        objectFit: "contain",
        padding: ".5rem",
      }}
    />
  );
};

export const EntityStatusHtml = (status) => {
  switch (status) {
    case 0:
      return <span className="dataStatusActive">Aktif</span>;
    case 1:
      return <span className="dataStatusPassive">Pasif</span>;
    case 2:
      return <span className="dataStatusPassive">Silinmis</span>;
    default:
      break;
  }
};

export const MediaTypeHtml = (status) => {
  switch (status) {
    case 0:
      return <span>Fotoğraf</span>;
    case 1:
      return <span>Video</span>;

    default:
      break;
  }
};

export const MediaTypeData = () => {
  return [
    {
      value: "0",
      label: "Fotoğraf",
    },
    {
      value: "1",
      label: "Video",
    },
  ];
};

export const IzsuStatusHtml = (status) => {
  switch (status) {
    case 0:
      return <span className="dataStatusOrange">Başvuru Kontrol</span>;
    case 1:
      return <span className="dataStatusOrange">Başvuru Alındı</span>;
    case 2:
      return <span className="dataStatusActive">Onaylandı</span>;
    case 3:
      return <span className="dataStatusPassive">Reddedildi</span>;
    default:
      break;
  }
};

export const MilitaryStatusHtml = (status) => {
  switch (status) {
    case 0:
      return "Yaptı";
    case 1:
      return "Tecilli";
    case 2:
      return "Muaf";
    default:
      break;
  }
};

export const MilitaryStatusData = () => {
  return [
    {
      value: "0",
      label: "Yaptı",
    },
    {
      value: "1",
      label: "Tecilli",
    },
    {
      value: "2",
      label: "Muaf",
    },
  ];
};

export const RegionHtml = (status) => {
  switch (status) {
    case 0:
      return "Ankara Hasanoğlan";
    case 1:
      return "Sakarya Arifiye";
    case 2:
      return "Samsun Ladik-Akpınar";
    case 3:
      return "Isparta Gönen";
    case 4:
      return "Kastamonu Göl";
    case 5:
      return "Adana Düziçi";
    case 6:
      return "Diyarbakır Dicle";
    case 7:
      return "Malatya Akçadağ";
    case 8:
      return "Van Ernis";
    case 9:
      return "Trabzon Beşikdüzü";
    case 10:
      return "Kars Cilavuz";
    case 11:
      return "Erzurum Pulur";
    case 12:
      return "Sivas Pamukpınar";
    case 13:
      return "Kayseri Pazarören";
    case 14:
      return "Konya (Ereğli) İvriz";
    case 15:
      return "Eskişehir Çifteler";
    case 16:
      return "Aydın Ortaklar";
    case 17:
      return "Antalya Aksu";
    case 18:
      return "İzmir Kızılçullu";
    case 19:
      return "Balıkkesir Savaştepe";
    case 20:
      return "Kırklareli Kepirtepe";

    default:
      break;
  }
};

export const RegionData = () => {
  return [
    { value: 0, label: "Ankara Hasanoğlan" },
    { value: 1, label: "Sakarya Arifiye" },
    { value: 2, label: "Samsun Ladik-Akpınar" },
    { value: 3, label: "Isparta Gönen" },
    { value: 4, label: "Kastamonu Göl" },
    { value: 5, label: "Adana Düziçi" },
    { value: 6, label: "Diyarbakır Dicle" },
    { value: 7, label: "Malatya Akçadağ" },
    { value: 8, label: "Van Ernis" },
    { value: 9, label: "Trabzon Beşikdüzü" },
    { value: 10, label: "Kars Cilavuz" },
    { value: 11, label: "Erzurum Pulur" },
    { value: 12, label: "Sivas Pamukpınar" },
    { value: 13, label: "Kayseri Pazarören" },
    { value: 14, label: "Konya (Ereğli) İvriz" },
    { value: 15, label: "Eskişehir Çifteler" },
    { value: 16, label: "Aydın Ortaklar" },
    { value: 17, label: "Antalya Aksu" },
    { value: 18, label: "İzmir Kızılçullu" },
    { value: 19, label: "Balıkkesir Savaştepe" },
    { value: 20, label: "Kırklareli Kepirtepe" },
  ];
};

export const AddictionAssessmentData = () => {
  return [
    {
      value: "0",
      label: "Bağımsız",
    },
    {
      value: "1",
      label: "Kısmı Bağımlı",
    },
    {
      value: "2",
      label: "Tam Bağımlı",
    },
  ];
};

export const AddictionAssessmentHtml = (value) => {
  switch (value) {
    case 0:
      return "Bağımsız";
    case 1:
      return "Kısmı Bağımlı";
    case 2:
      return "Tam Bağımlı";
    default:
      break;
  }
};

export const SupportTypeHtml = (status) => {
  switch (status) {
    case 0:
      return <span>Web Başvuru</span>;
    case 1:
      return <span>Randevu</span>;
    case 2:
      return <span>Eşya</span>;
    case 3:
      return <span>İzsu Fatura</span>;
    case 4:
      return <span>Ürün</span>;
    case 5:
      return <span>Link</span>;
    default:
      break;
  }
};

export const SupportTypeData = () => {
  const data = [
    {
      value: 0,
      text: "Web Başvuru",
    },
    {
      value: 1,
      text: "Randevu",
    },
    {
      value: 2,
      text: "Eşya",
    },
    {
      value: 3,
      text: "İzsu Fatura",
    },
    {
      value: 4,
      text: "Ürün",
    },
    {
      value: 5,
      text: "Link",
    },
  ];
  return data;
};

export const EntityStatusData = () => {
  return [
    {
      value: 0,
      text: "Aktif",
    },
    {
      value: 1,
      text: "Pasif",
    },
  ];
};

export const GenderData = () => {
  return [
    {
      value: 0,
      label: "Kadın",
    },
    {
      value: 1,
      label: "Erkek",
    },
  ];
};

export const MaritalData = () => {
  return [
    {
      value: "0",
      label: "Evli",
    },
    {
      value: "1",
      label: "Bekar",
    },
  ];
};

export const EducationLevelData = () => {
  return [
    {
      value: "0",
      label: "Okur Yazar",
    },
    {
      value: "1",
      label: "İlköğretim",
    },

    {
      value: "2",
      label: "Lise",
    },
    {
      value: "3",
      label: "Ön Lisans",
    },
    {
      value: "4",
      label: "Lisans",
    },
    {
      value: "5",
      label: "Yüksek Lisans",
    },
    {
      value: "6",
      label: "Doktora",
    },
  ];
};

export const EducationLevelHtml = (value) => {
  switch (value) {
    case "0":
      return <span>Okur Yazar</span>;
    case "1":
      return <span>İlköğretim</span>;
    case "2":
      return <span>Lise</span>;
    case "3":
      return <span>Ön Lisans</span>;
    case "4":
      return <span>Lisans</span>;
    case "5":
      return <span>Yüksek Lisans</span>;
    case "6":
      return <span>Doktora</span>;

    default:
      break;
  }
};
export const DriverLicenseTypeData = () => {
  return [
    {
      value: "0",
      label: "M",
    },
    {
      value: "1",
      label: "A1",
    },

    {
      value: "2",
      label: "A2",
    },
    {
      value: "3",
      label: "A",
    },
    {
      value: "4",
      label: "B1",
    },
    {
      value: "5",
      label: "B",
    },
    {
      value: "6",
      label: "BE",
    },
    {
      value: "7",
      label: "C1",
    },
    {
      value: "8",
      label: "C1E",
    },
    {
      value: "9",
      label: "C",
    },
    {
      value: "10",
      label: "CE",
    },
    {
      value: "11",
      label: "D1",
    },
    {
      value: "12",
      label: "D1E",
    },
    {
      value: "13",
      label: "D",
    },
    {
      value: "14",
      label: "DE",
    },
    {
      value: "15",
      label: "F",
    },
  ];
};
export const DriverLicenseTypeHtml = (value) => {
  switch (value) {
    case 0:
      return <span>M</span>;
    case 1:
      return <span>A1</span>;
    case 2:
      return <span>A2</span>;
    case 3:
      return <span>A</span>;
    case 4:
      return <span>B1</span>;
    case 5:
      return <span>B</span>;
    case 6:
      return <span>BE</span>;
    case 7:
      return <span>C1</span>;
    case 8:
      return <span>C1E</span>;
    case 9:
      return <span>C</span>;
    case 10:
      return <span>CE</span>;
    case 11:
      return <span>D1</span>;
    case 12:
      return <span>D1E</span>;
    case 13:
      return <span>D</span>;
    case 14:
      return <span>DE</span>;
    case 15:
      return <span>F</span>;

    default:
      break;
  }
};
export const DisabledTypeData = () => {
  const data = [
    {
      value: 0,
      text: "Zihinsel Engelli",
    },
    {
      value: 1,
      text: "İşitme Engelli",
    },
    {
      value: 2,
      text: "Görme Engelli",
    },
    {
      value: 3,
      text: "Ortopedik Engelli",
    },
    {
      value: 4,
      text: "Dil ve Konuşma Engelli",
    },
    {
      value: 5,
      text: "Ruhsal ve Duygusal Hastalığı Olan",
    },
    {
      value: 6,
      text: "Süreğen Hastalık",
    },
    {
      value: 7,
      text: "Dikkat Eksikliği ve Hiperaktivite Bozukluğu",
    },
  ];
  return data;
};
export const DisabledTypeHtml = (value) => {
  switch (value) {
    case 0:
      return <span>Zihinsel Engelli</span>;
    case 1:
      return <span>İşitme Engelli</span>;
    case 2:
      return <span>Görme Engelli</span>;
    case 3:
      return <span>Ortopedik Engelli</span>;
    case 4:
      return <span>Dil ve Konuşma Engelli</span>;
    case 5:
      return <span>Ruhsal ve Duygusal Hastalığı Olan</span>;
    case 6:
      return <span>Süreğen Hastalık</span>;
    case 7:
      return <span>Dikkat Eksikliği ve Hiperaktivite Bozukluğu</span>;
    default:
      break;
  }
};

export const EducationTypeData = () => {
  return [
    {
      value: "0",
      label: "Örgün Öğretim",
    },
    {
      value: "1",
      label: "İkinci Öğretim",
    },
    {
      value: "2",
      label: "Açık Öğretim",
    },
    {
      value: "3",
      label: "Uzaktan Öğretim",
    },
  ];
};

export const EducationTypeHtml = (value) => {
  switch (value) {
    case 0:
      return "Örgün Öğretim";
    case 1:
      return "İkinci Öğretim";
    case 2:
      return "Açık Öğretim";
    case 3:
      return "Uzaktan Öğretim";
    default:
      break;
  }
};

export const GraduateStatusHtml = (value) => {
  switch (value) {
    case 0:
      return "Devam Ediyorum";
    case 1:
      return "Terk";
    case 2:
      return "Mezun";
    default:
      break;
  }
};

export const ProductPaymentTypeData = () => {
  return [
    {
      value: 0,
      text: "Kredi Karti",
    },
    {
      value: 1,
      text: "Iban",
    },
    {
      value: 2,
      text: "Kredi Karti & Iban",
    },
  ];
};

export const ProductPaymentTypeHtml = (value) => {
  switch (value) {
    case 0:
      return "Kredi Karti";
    case 1:
      return "Iban";
    case 2:
      return "Kredi Karti & Iban";
    default:
      break;
  }
};

export const LangLongNameData = () => {
  return [
    {
      value: 0,
      text: "Türkçe",
    },
    {
      value: 1,
      text: "İngilizce",
    },
    {
      value: 2,
      text: "Almanca",
    },
  ];
};

export const LangLongNameHtml = (lang) => {
  switch (lang) {
    case 0:
      return "Türkçe";
    case 1:
      return "İngilizce";
    case 2:
      return "Almanca";
    default:
      break;
  }
};

export const LangLevelData = () => {
  return [
    {
      value: "0",
      label: "Başlangıç",
    },
    {
      value: "1",
      label: "Temel",
    },
    {
      value: "2",
      label: "Orta",
    },
    {
      value: "3",
      label: "İyi",
    },
    {
      value: "4",
      label: "İleri",
    },
  ];
};

export const LangLeveltml = (lang) => {
  switch (lang) {
    case 0:
      return "Başlangıç";
    case 1:
      return "Temel";
    case 2:
      return "Orta";
    case 3:
      return "İyi";
    case 4:
      return "İleri";
    default:
      break;
  }
};

export const LangData = () => {
  return [
    {
      value: 0,
      text: "Tr",
    },
    {
      value: 1,
      text: "En",
    },
  ];
};

export const LangHtml = (lang) => {
  switch (lang) {
    case 0:
      return "Tr";
    case 1:
      return "En";
    default:
      break;
  }
};

export const ProductTypeData = () => {
  const lang = [
    {
      value: 0,
      text: "Ürün",
    },
    {
      value: 1,
      text: "Bilet",
    },
    // {
    //   value: 2,
    //   text: "Dijital Ürün",
    // },
    // {
    //   value: 3,
    //   text: "Link (Biletix vb.)",
    // },
  ];
  return lang;
};

export const ProductTypeHtml = (lang) => {
  switch (lang) {
    case 0:
      return "Ürün";
    case 1:
      return "Bilet";
    // case 2:
    //   return "Dijital Ürün";
    // case 3:
    //   return "Link (Biletix vb.)";
    default:
      break;
  }
};

export const ProductQuantityTypeData = () => {
  const lang = [
    {
      value: 0,
      text: "Limitli (Stoklu)",
    },
    {
      value: 1,
      text: "Limitsiz (Stoksuz)",
    },
  ];
  return lang;
};

export const ProductQuantityTypeHtml = (lang) => {
  switch (lang) {
    case 0:
      return "Limitli (Stoklu)";
    case 1:
      return "Limitsiz (Stoksuz)";
    default:
      break;
  }
};

export const OrderStatusHtml = (status) => {
  switch (status) {
    case 0:
      return <span className="dataStatusInfo">Oluşturuldu</span>;
    case 1:
      return <span className="dataStatusActive">Başarılı</span>;
    case 2:
      return <span className="dataStatusPassive">İptal Edildi</span>;
    case 3:
      return <span className="dataStatusOrange">İade Edildi"</span>;
    case 4:
      return <span className="dataStatusPassive">Ödeme Hata</span>;
    case 5:
      return <span className="dataStatusInfo">Iban Ödeme Onayı Bekliyor</span>;

    default:
      break;
  }
};
