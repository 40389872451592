import React, { Fragment, useContext, useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import classes from "./SidebarClient.module.css";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/app-context";

export const SidebarClient = (props) => {
  const appContext = useContext(AppContext);
  const [pathName, setPathName] = useState();
  const { toggleSidebar } = useProSidebar();

  const MenuHandler = (pathName) => {
    setPathName(pathName);
    toggleSidebar();
  };

  const MenuItemHandler = (url, text, data) => {
    return (
      <MenuItem
        active={pathName === url}
        component={<Link to={url} state={data} />}
        onClick={() => MenuHandler(url)}
      >
        <div className={`menuItemContentBox ${classes.menuItemContentBox}`}>
          <span className={classes.menuItemContentText}>{text}</span>
        </div>
      </MenuItem>
    );
  };

  return (
    <Fragment>
      <Sidebar
        defaultCollapsed={false}
        breakPoint="xxl"
        backgroundColor="#f5f5f5"
        rootStyles={{
          border: "none",
          backgroundColor: "#043657",
          boxShadow: "0 2px 15px rgba(0, 0, 0, .3)",
          height: "100vh",
        }}
      >
        <Link to="/" className={classes.logo}>
          <img
            src="/assets/img/logo.png"
            alt="logo"
            style={{ width: "150px" }}
          ></img>
        </Link>

        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // if (level === 0) {

              // }
              return {
                color: disabled ? "#ccc" : active ? "#fff" : "#043657",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#065386",
                },
                backgroundColor: active && "#004280",
                fontWeight: "bold",
                fontSize: ".9rem",
              };
            },
          }}
          rootStyles={{ paddingBottom: "2rem" }}
        >
          {appContext.user && (
            <div className={classes.userContainer}>
              <div className={classes.userPic}>
                <img
                  src={
                    appContext.user.profilePicture
                      ? appContext.user.profilePicture
                      : "/assets/img/noProfile.png"
                  }
                  alt=""
                />
              </div>
              <div className={classes.userInfo}>
                <h3>{appContext.user.fullName}</h3>
              </div>
              <div className={classes.userLink}>
                {MenuItemHandler("/jobApplication", "Başvurduğum İlanlar")}
                {MenuItemHandler("/account", "Hesap Bilgilerim")}
                {MenuItemHandler("/cv", "Özgeçmişim")}
              </div>
            </div>
          )}
          <hr />
          {MenuItemHandler("/aboutUs", "Hakkımızda")}
          {!appContext.isLoggedIn && MenuItemHandler("/cv", "Özgeçmiş Yükle")}
          {MenuItemHandler("/job/list", "İlanlar")}
          {MenuItemHandler("/faq", "S.S.S.")}
          <hr />
          {!appContext.isLoggedIn ? (
            <div className={classes.buttonBox}>
              <Link className="btnBlue" to="/login" state={{ pageType: 0 }}>
                GİRİŞ YAP
              </Link>
              <Link
                className="btnBlueBorder"
                to="/login"
                state={{ pageType: 1 }}
              >
                ÜYE OL
              </Link>
            </div>
          ) : (
            <button
              className={classes.exitButton}
              onClick={() => appContext.LogoutHandler()}
            >
              Çıkış
            </button>
          )}
        </Menu>
      </Sidebar>
    </Fragment>
  );
};
