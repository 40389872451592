import {
  faBlog,
  faBuilding,
  faComment,
  faCommentAlt,
  faCommenting,
  faDashboard,
  faImages,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

import classes from "./SidebarAdmin.module.css";

export const SidebarAdmin = (props) => {
  const [pathName, setPathName] = useState();
  const { toggleSidebar } = useProSidebar();

  const MenuHandler = (pathName) => {
    setPathName(pathName);
    toggleSidebar();
  };

  const MenuItemHandler = (url, text, icon) => {
    return (
      <MenuItem
        active={pathName === url}
        component={<Link to={url} />}
        onClick={() => MenuHandler(url)}
      >
        <div className={`menuItemContentBox ${classes.menuItemContentBox}`}>
          <FontAwesomeIcon
            icon={icon}
            className={classes.menuItemContentIcon}
          />
          {props.showText && (
            <span className={classes.menuItemContentText}>{text}</span>
          )}
        </div>
      </MenuItem>
    );
  };

  return (
    <Sidebar
      breakPoint="md"
      backgroundColor="#60afc7"
      rootStyles={{
        border: "none",
        backgroundColor: "#043657",
        boxShadow: "0 2px 15px rgba(0, 0, 0, .3)",
        height: "100vh",
      }}
    >
      <div className={classes.logo}>
        <img
          src="/assets/img/logo.png"
          alt="logo"
          style={props.showText ? { width: "150px" } : { width: "50px" }}
        ></img>
        {/* {props.showText && (
          <span
            style={{ marginLeft: "1rem", fontWeight: "bold", color: "white" }}
          >
            Memnunsan
          </span>
        )} */}
      </div>

      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // if (level === 0) {
            //
            // }
            return {
              color: disabled ? "#ccc" : "#fff",
              "&:hover": {
                backgroundColor: "#376472",
                color: "#fff",
              },
              backgroundColor: active && "#376472",
              fontWeight: "bold",
              fontSize: ".9rem",
            };
          },
        }}
        rootStyles={{ paddingBottom: "2rem" }}
      >
        {MenuItemHandler("/admin", "Dashboard", faDashboard)}
        {MenuItemHandler("/admin/slider", "Slider", faImages)}
        {MenuItemHandler("/admin/blog", "Blog", faBlog)}
        {MenuItemHandler("/admin/category", "Kategori", faList)}
        {MenuItemHandler("/admin/company", "Şirket", faBuilding)}
        <SubMenu
          label="Yorumlar"
          icon={
            <FontAwesomeIcon icon={faComment} className="menuItemContentIcon" />
          }
          active={pathName === "/admin/comment"}
        >
          {MenuItemHandler(
            "/admin/comment/waitingApprove",
            "Onay Bekleyen",
            faCommenting,
          )}
          {MenuItemHandler("/admin/comment", "Tüm Yorumlar", faComment)}
        </SubMenu>
      </Menu>
    </Sidebar>
  );
};
