import React from "react";
import classes from "./CommentBox.module.css";
import ReviewStar from "../../ui/reviewStar/ReviewStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Link } from "react-router-dom";
import moment from "moment";

const CommentBox = ({ data, widget }) => {
  const asd = [1, 2, 3, 4, 5, 6];
  return (
    <div className={widget ? classes.containerWidget : classes.containerColumn}>
      {data &&
        data.map((x, i) => (
          <div className={widget ? classes.itemWidget : classes.item} key={i}>
            <div className={classes.top}>
              <div className={classes.profile}>
                <img src="/assets/img/noProfile.png" alt="" />
                <h3>{x.fullName}</h3>
              </div>
              <div className={classes.reviewStar}>
                <ReviewStar rate={x.rate} />
                <p>{moment(x.updatedDate).format("DD/MM/YYYY")}</p>
              </div>
            </div>
            {widget && (
              <Link
                to={`/company/${x.companyName}/${x.companyId}`}
                className={classes.companyName}
              >
                {x.companyName}
              </Link>
            )}
            <div className={classes.middle}>
              <h3>{x.title}</h3>
              <p className={widget ? classes.widgetP : ""}>{x.comment}</p>

              <div
                className={classes.likeContainer}
                style={{ margin: widget ? "0" : "1rem 0" }}
              >
                <div className={classes.likeBox}>
                  <FontAwesomeIcon icon={faThumbsUp} />
                  <span style={{ marginLeft: ".5rem" }}>123</span>
                </div>
                <div className={classes.unLikeBox}>
                  <FontAwesomeIcon icon={faThumbsDown} />
                  <span style={{ marginLeft: ".5rem" }}>80</span>
                </div>
              </div>
            </div>
            <div className={classes.bottom}>
              {x.youtube != null && (
                <div className={classes.youtube}>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/Hx88iehT9BQ"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              )}

              {/*<div*/}
              {/*  className={classes.media}*/}
              {/*  style={{ flex: widget ? "unset" : 1 }}*/}
              {/*>*/}
              {/*  <PhotoProvider>*/}
              {/*    <PhotoView src="/assets/img/chair.png">*/}
              {/*      <img*/}
              {/*        src="/assets/img/chair.png"*/}
              {/*        alt=""*/}
              {/*        className={classes.mediaItem}*/}
              {/*      />*/}
              {/*    </PhotoView>*/}
              {/*    <PhotoView src="/assets/img/chair.png">*/}
              {/*      <img*/}
              {/*        src="/assets/img/chair.png"*/}
              {/*        alt=""*/}
              {/*        className={classes.mediaItem}*/}
              {/*      />*/}
              {/*    </PhotoView>*/}
              {/*    <PhotoView src="/assets/img/chair.png">*/}
              {/*      <img*/}
              {/*        src="/assets/img/chair.png"*/}
              {/*        alt=""*/}
              {/*        className={classes.mediaItem}*/}
              {/*      />*/}
              {/*    </PhotoView>*/}
              {/*    <PhotoView src="/assets/img/chair.png">*/}
              {/*      <img*/}
              {/*        src="/assets/img/chair.png"*/}
              {/*        alt=""*/}
              {/*        className={classes.mediaItem}*/}
              {/*      />*/}
              {/*    </PhotoView>*/}
              {/*    <PhotoView src="/assets/img/chair.png">*/}
              {/*      <img*/}
              {/*        src="/assets/img/chair.png"*/}
              {/*        alt=""*/}
              {/*        className={classes.mediaItem}*/}
              {/*      />*/}
              {/*    </PhotoView>*/}
              {/*    <PhotoView src="/assets/img/chair.png">*/}
              {/*      <img*/}
              {/*        src="/assets/img/chair.png"*/}
              {/*        alt=""*/}
              {/*        className={classes.mediaItem}*/}
              {/*      />*/}
              {/*    </PhotoView>*/}
              {/*    <PhotoView src="/assets/img/chair.png">*/}
              {/*      <img*/}
              {/*        src="/assets/img/chair.png"*/}
              {/*        alt=""*/}
              {/*        className={classes.mediaItem}*/}
              {/*      />*/}
              {/*    </PhotoView>*/}
              {/*    <PhotoView src="/assets/img/chair.png">*/}
              {/*      <img*/}
              {/*        src="/assets/img/chair.png"*/}
              {/*        alt=""*/}
              {/*        className={classes.mediaItem}*/}
              {/*      />*/}
              {/*    </PhotoView>*/}
              {/*  </PhotoProvider>*/}
              {/*</div>*/}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CommentBox;
