import React from "react";

export const AppContext = React.createContext({
  isLoggedIn: false,
  isAdmin: false,
  isMobile: false,
  loader: false,
  token: null,
  user: null,
  categoryData: [],
  setLoader: () => {},
  IsLoggedInAsync: () => {},
  LoginHandler: (data, isAdmin) => {},
  LoginRecaptchaHandler: (data, isAdmin) => {},
  LogoutHandler: () => {},
  FetchHandler: (url, method, contentType, data) => {},
  GetGRecapToken: () => {},
  VerifyPhone: (data) => {},
});
