import React, { Fragment, useContext, useState } from "react";
import { Input } from "../../ui/input/Input";
import {
  SwalErrorMessage,
  SwallApprove,
  SwalSuccess,
  SwalSuccessMessage,
} from "../../../util/swalUtil";
import { AppContext } from "../../../context/app-context";
import classes from "./LoginForm.module.css";
import { CheckFormValid, Validation } from "../../ui/input/Validation";
import Swal from "sweetalert2";
import HTMLReactParser from "html-react-parser";

export const LoginForm = ({ setShowModal }) => {
  const appContext = useContext(AppContext);
  const apiUrl = "auth";
  const [formInput, setFormInput] = useState({
    fullName: null,
    password: null,
    birthDate: null,
    phoneNumber: null,
    email: null,
    kvkk: null,
    otp: null,
    gToken: null,
  });
  const [formType, setFormType] = useState(0);
  const [title, setTitle] = useState("Giriş Yap");
  const [loginFormIsValid, setLoginFormIsValid] = useState({
    email: {
      status: false,
      message: "Email: Zorunludur.",
    },
    password: {
      status: false,
      message: "Şifre: Zorunludur.",
    },
  });

  const [forgotPasswordFormIsValid, setForgotPasswordFormIsValid] = useState({
    email: {
      status: false,
      message: "Email: Zorunludur.",
    },
  });

  const [registerFormIsValid, setRegisterFormIsValid] = useState({
    fullName: { status: false, message: "Ad Soyad: Zorunludur." },
    email: { status: false, message: "Email: Zorunludur." },
    phoneNumber: { status: false, message: "Telefon Numarası: Zorunludur." },
    password: { status: false, message: "Şifre: Zorunludur." },
    birthDate: { status: false, message: "Doğum Tarihi: Zorunludur." },
    kvkk: { status: false, message: "KVKK: Onayı zorunludur." },
  });

  const [verifyFormIsValid, setVerifyFormIsValid] = useState({
    otp: { status: false, message: "KVKK: Onayı zorunludur." },
  });

  const SubmitHandler = async () => {
    let isValid = { status: false, message: "Hata" };
    let submitUrl = "login";
    switch (formType) {
      case 0:
        isValid = CheckFormValid(loginFormIsValid);
        break;
      case 1:
        isValid = CheckFormValid(forgotPasswordFormIsValid);
        submitUrl = "forgotPassword";
        break;
      case 2:
        isValid = CheckFormValid(registerFormIsValid);
        submitUrl = "register";
        break;
      case 3:
        isValid = CheckFormValid(verifyFormIsValid);
        submitUrl = "verifyEmail";
        break;
    }
    if (!isValid.status) {
      SwalErrorMessage(isValid.message);
      return;
    }

    if (formType === 0) {
      appContext.LoginHandler(formInput, false);
    } else {
      const response = await appContext.FetchHandler(
        `${apiUrl}/${submitUrl}`,
        "post",
        null,
        formInput,
      );

      if (response.ok) {
        SwalSuccessMessage(response.data);
        if (formType === 2) {
          setFormType(3);
          setTitle("Doğrula");
        } else if (formType === 3) {
          setTitle("Giriş Yap");
          setFormType(0);
          setLoginFormIsValid({
            email: { status: true, message: "" },
            password: { status: true, message: "" },
          });
        } else {
          setShowModal(false);
        }
      }
    }
  };
  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginLeft}>
        <h1>{title}</h1>
        <form
          id="form"
          onSubmit={async (event) => {
            event.preventDefault();
            await SubmitHandler();
          }}
        >
          {formType === 0 ? (
            <Fragment>
              <Input
                label="Email"
                type="input"
                className={classes.input}
                validation={formInput?.email != null && loginFormIsValid.email}
                input={{
                  id: "Email",
                  type: "text",
                  placeholder: "Email",
                  value:
                    formInput != null && formInput.email != null
                      ? formInput.email
                      : "",
                  onChange: (event) => {
                    setLoginFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setForgotPasswordFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setRegisterFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      email: event.target.value,
                    }));
                  },
                }}
              />
              <div className={classes.passwordBox}>
                <Input
                  label="Şifre"
                  type="input"
                  className={classes.input}
                  validation={
                    formInput?.password != null && loginFormIsValid.password
                  }
                  input={{
                    id: "password",
                    type: "password",
                    placeholder: "Şifre",
                    value:
                      formInput != null && formInput.password != null
                        ? formInput.password
                        : "",
                    onChange: (event) => {
                      const value = event.target.value;
                      setLoginFormIsValid((prev) => ({
                        ...prev,
                        password: Validation("Şifre", value, {
                          type: 4,
                          isRequired: true,
                        }),
                      }));
                      setRegisterFormIsValid((prev) => ({
                        ...prev,
                        password: Validation("Şifre", value, {
                          type: 4,
                          isRequired: true,
                        }),
                      }));
                      setFormInput((prev) => ({
                        ...prev,
                        password: event.target.value,
                      }));
                    },
                  }}
                />
                <button
                  type="button"
                  onClick={() => {
                    setFormType(1);
                    setTitle("Şifremi Unuttum");
                  }}
                >
                  Şifremi Unuttum?
                </button>
              </div>
            </Fragment>
          ) : formType === 1 ? (
            <Fragment>
              <p>Şifreniz Email adresinize gönderilecektir.</p>
              <Input
                label="Email"
                type="input"
                className={classes.input}
                validation={
                  formInput?.email != null && forgotPasswordFormIsValid.email
                }
                input={{
                  id: "email",
                  type: "email",
                  placeholder: "Email",
                  value:
                    formInput != null && formInput.email != null
                      ? formInput.email
                      : "",
                  onChange: (event) => {
                    setForgotPasswordFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      email: event.target.value,
                    }));
                  },
                }}
              />
            </Fragment>
          ) : formType === 2 ? (
            <Fragment>
              <Input
                label="Ad Soyad"
                type="input"
                className={classes.input}
                validation={
                  formInput?.fullName != null && registerFormIsValid.fullName
                }
                input={{
                  id: "fullName",
                  type: "text",

                  placeholder: "Ad Soyad",
                  value:
                    formInput != null && formInput.fullName != null
                      ? formInput.fullName
                      : "",
                  onChange: (event) => {
                    setRegisterFormIsValid((prev) => ({
                      ...prev,
                      fullName: Validation("Ad Soyad", event.target.value, {
                        isRequired: true,
                        min: 2,
                        max: 30,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      fullName: event.target.value,
                    }));
                  },
                }}
              />
              <Input
                label="Email"
                type="input"
                className={classes.input}
                validation={
                  formInput?.email != null && registerFormIsValid.email
                }
                input={{
                  id: "email",
                  type: "email",
                  placeholder: "Email",
                  value:
                    formInput != null && formInput.email != null
                      ? formInput.email
                      : "",
                  onChange: (event) => {
                    setRegisterFormIsValid((prev) => ({
                      ...prev,
                      email: Validation("Email", event.target.value, {
                        isRequired: true,
                        type: 2,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      email: event.target.value,
                    }));
                  },
                }}
              />
              <Input
                label="Doğum Tarihi"
                type="input"
                className={classes.input}
                validation={
                  formInput?.birthDate != null && registerFormIsValid.birthDate
                }
                input={{
                  id: "birthDate",
                  type: "date",
                  placeholder: "Doğum Tarihi",
                  onChange: (event) => {
                    setRegisterFormIsValid((prev) => ({
                      ...prev,
                      birthDate: Validation(
                        "Doğum Tarihi",
                        event.target.value,
                        {
                          isRequired: true,
                          type: 3,
                        },
                      ),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      birthDate: event.target.value,
                    }));
                  },
                }}
              />
              <Input
                label="Telefon Numarası"
                type="phoneNumber"
                className={classes.input}
                onlyCountries={["tr"]}
                validation={
                  formInput.phoneNumber != null &&
                  registerFormIsValid.phoneNumber
                }
                input={{
                  id: "phoneNumber",
                  type: "text",
                  onChange: (event) => {
                    setRegisterFormIsValid((prev) => ({
                      ...prev,
                      phoneNumber: Validation("Telefon Numarası", event, {
                        isRequired: true,
                        min: 12,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      phoneNumber: event,
                    }));
                  },
                }}
              />
              <Input
                type="input"
                style={{ width: "100%" }}
                validation={
                  formInput.password != null && registerFormIsValid.password
                }
                label="Şifre"
                input={{
                  id: "password",
                  type: "password",
                  placeholder: "Şifre",
                  onChange: (event) => {
                    const value = event.target.value;
                    setRegisterFormIsValid((prev) => ({
                      ...prev,
                      password: Validation("Şifre", value, {
                        type: 4,
                        isRequired: true,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      password: value,
                    }));
                  },
                }}
              />
              <div className="row">
                <Input
                  disableLabel={true}
                  label={HTMLReactParser(`<span><span style="color:#FF0000;font-weight:bold">KVKK</span>
              okudum onaylıyorum.</span>`)}
                  style={{ width: "100%", padding: 0 }}
                  validation={
                    formInput.kvkk != null && registerFormIsValid.kvkk
                  }
                  type="input"
                  checked={formInput.kvkk != null && formInput.kvkk}
                  checkboxStyle={{
                    justifyContent: "flex-start",
                    border: "none",
                    height: "1.2rem",
                  }}
                  input={{
                    id: "kvkk",
                    type: "checkbox",

                    onChange: (event) => {
                      Swal.fire({
                        width: 1000,
                        html: "kvkk",
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonColor: "#118c11",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Evet",
                        cancelButtonText: "Hayır",
                      }).then(async (result) => {
                        const element = document.getElementById(
                          event.target.id,
                        );
                        if (result.isConfirmed) {
                          element.checked = true;
                          setRegisterFormIsValid((prev) => ({
                            ...prev,
                            kvkk: { status: true, message: "" },
                          }));
                          setFormInput((prev) => ({
                            ...prev,
                            kvkk: true,
                          }));
                        } else {
                          element.checked = false;
                          setFormInput((prev) => ({
                            ...prev,
                            kvkk: false,
                          }));
                        }
                      });
                    },
                  }}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <p>Email adresine gönderilen doğrulama kodunu giriniz.</p>
              <Input
                label="Doğrulama Kodu"
                type="input"
                className={classes.input}
                validation={formInput?.otp != null && verifyFormIsValid.otp}
                input={{
                  id: "otp",
                  type: "text",
                  placeholder: "Doğrulama Kodu",
                  value:
                    formInput != null && formInput.otp != null
                      ? formInput.otp
                      : "",
                  onChange: (event) => {
                    setVerifyFormIsValid((prev) => ({
                      ...prev,
                      otp: Validation("Doğrulama Kodu", event.target.value, {
                        isRequired: true,
                        min: 6,
                        max: 6,
                      }),
                    }));
                    setFormInput((prev) => ({
                      ...prev,
                      otp: event.target.value,
                    }));
                  },
                }}
              />
            </Fragment>
          )}

          <div className={classes.bottomBtnBox}>
            <button type="submit" className={classes.loginBtn}>
              {title}
            </button>
            {formType !== 0 && formType !== 3 && (
              <button
                type="button"
                onClick={() => {
                  setFormType(0);
                  setTitle("Giriş Yap");
                }}
              >
                Giriş Yap
              </button>
            )}
            {formType === 0 && (
              <button
                type="button"
                onClick={() => {
                  setFormType(2);
                  setTitle("Kayıt Ol");
                }}
              >
                Kayıt Ol
              </button>
            )}
          </div>
        </form>
      </div>
      <div className={classes.loginRight}>
        <img src="/assets/img/loginImage.png" alt="" />
      </div>
    </div>
  );
};
